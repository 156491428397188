<template>
  <div class="app-container">
    <div class="search">
      <van-search v-model="param.q" :placeholder="$t('staff.department')" />
      <div class="search-btn" @click="handleSearch">{{$t('staff.search')}}</div>
    </div>
    <!-- 医生列表 -->
    <div class="doctorList">
      <div class="dropdown-menu">
        <div class="department" @click="getDepartment" ref="department">
          <div>{{deptTitle()}}</div>
          <div><van-icon name="play" ref="department_icon" /></div>
        </div>
        <div class="consult" @click="getConsult" ref="consult">
          <div>{{doctorState}}</div>
          <div><van-icon name="play" ref="consult_icon" /></div>
        </div>
        <div class="department-list-box" v-show="!isDepartment">
          <div class="list-cont">
            <ul class="department-list" ref="list1">
              <li
                class="list1"
                v-for="(item, index) in deptList"
                :key="index"
                @click="showList2(item)"
                :data-id="item.id"
                :class="item.id === dept.id ? 'list1-sel' : ''"
              >
                <!-- <img v-if="item.icoUrl && item.icoUrl.length>0" :src="item.icoUrl" alt="" class="img1" /> -->
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
            <ul
              class="list2_box"
              ref="list2"
              v-if="dept.subDepts.length > 0"
            >
              <li
                class="list2"
                v-for="(item, index) in dept.subDepts"
                :key="index"
                @click.stop="showList3(item)"
                :data-id="item.id"
                :class="item.id === subDept.id ? 'list2-sel' : ''"
              >
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
            <ul
              class="list3_box"
              ref="list3"
              v-if="subDept.subDepts.length > 0"
            >
              <li
                class="list3"
                v-for="(item, index) in subDept.subDepts"
                :key="index"
                @click.stop="showList4(item)"
                :data-id="item.id"
                :class="item.id === thirDept.id ? 'list3-sel' : ''"
              >
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="department-list-box" v-show="!isConsult">
          <ul class="consult-list" v-show="!isConsult">
            <li class="line-All" @click="line_All">{{$t("staff.entire")}}</li>
            <li class="on-line" @click="on_line">{{$t("staff.currentOnline")}}</li>
            <li class="off-line" @click="off_line">{{$t("staff.currenntOffline")}}</li>
          </ul>
        </div>
        <div class="department-list-box-mask" v-show="!isDepartment || !isConsult"/>
      </div>
      <div class="list-content">
        <ul>
          <li class="doctor-content"
              v-for="item in doctorList"
              :key="item.userId"
              @click="$router.push(`/staff/detail/${item.userId}`)">
            <img :src="item.userFaceUrl" alt="" class="head-portrait" />
            <div class="content">
              <div class="content-title">
                <h2>{{ item.userName }}</h2>
                <span>{{ item.titleName }}/{{ item.deptName }}</span>
                <img
                  src="@/assets/images/consult.png"
                  alt=""
                  v-show="item.userIsConsultable"
                />
              </div>
              <div class="content-text">
                {{$t("staff.beAdeptat")}}：{{ item.userSkills || $t("staff.notBeAdeptat") }}
              </div>
            </div>
          </li>
        </ul>
      </div >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'search',
    data() {
      return {
        param: {
          q: '',
          deptId: 0
        },
        doctorState: this.$t("staff.consultState"),
        isDepartment: true,
        isConsult: true,
        deptList: [],
        dept: {subDepts:[]},
        subDept: {subDepts:[]},
        thirDept: {},
        doctorList: [],
        oldDoctorList: [],
        deptId: this.$route.query.deptId || 0
      }
    },
    created() {
      this.getDept()
      this.getDoctor()
      document.title = this.$t("staff.doctorList");
    },
    mounted() {

    },
    methods: {
      getDepartment() {
        if (this.isDepartment) {
          // this.isConsult = false
          this.$refs.department.classList.add('department-active');
          this.$refs.department_icon.classList.add('van-icon-play-active');
          this.isDepartment = false;
        } else {
          this.$refs.department.classList.remove('department-active');
          this.$refs.department_icon.classList.remove('van-icon-play-active');
          this.isDepartment = true;
        }
        if (!this.isConsult) {
          this.$refs.consult.classList.remove('department-active');
          this.$refs.consult_icon.classList.remove('van-icon-play-active');
          this.isConsult = true;
        }
      },
      deptTitle() {
        var title = this.thirDept.name || this.subDept.name || this.dept.name || this.$t("staff.keshi")
        return title === this.$t('staff.entire') ? this.$t("staff.keshi") : title
      },
      getConsult() {
        if (this.isConsult) {
          // this.isDepartment = false
          this.$refs.consult.classList.add('department-active');
          this.$refs.consult_icon.classList.add('van-icon-play-active');
          this.isConsult = false;
        } else {
          this.$refs.consult.classList.remove('department-active');
          this.$refs.consult_icon.classList.remove('van-icon-play-active');
          this.isConsult = true;
        }
        if (!this.isDepartment) {
          this.$refs.department.classList.remove('department-active');
          this.$refs.department_icon.classList.remove('van-icon-play-active');
          this.isDepartment = true;
        }
      },
      async getDept() {
        this.deptList = []
        const res = await this.$axios1.post("/dept/list")
        this.deptList.push({name:this.$t('staff.entire'), id: 0, icoUrl: '', subDepts: []})
        for (let i = 0; i < res.data.length; i++) {
          this.deptList.push(res.data[i])
          if (this.deptId !== 0) {
            if (res.data[i].id == this.deptId) {
              this.dept = res.data[i]
              // console.log(this.dept.id)
              this.getDoctor()
            } else if (res.data[i].subDepts) {
              for (let j = 0; j < res.data[i].subDepts.length; j++) {
                // console.log(res.data[i].subDepts[j].id)
                if (res.data[i].subDepts[j].id == this.deptId) {
                  this.dept = res.data[i]
                  this.subDept = res.data[i].subDepts[j]
                  // console.log(this.subDept.id)
                  this.getDoctor()
                } else if (res.data[i].subDepts[j].subDepts) {
                  for (let k = 0; k < res.data[i].subDepts[j].subDepts.length; k++) {
                    if (res.data[i].subDepts[j].subDepts[k].id == this.deptId) {
                      this.dept = res.data[i]
                      this.subDept = res.data[i].subDepts[j]
                      this.thirDept = res.data[i].subDepts[j].subDepts[k]
                      // console.log(this.thirDept.id)
                      this.getDoctor()
                    }
                  }
                }
              }
            }
          }
        }
      },
      async getDoctor() {
        this.param.deptId = this.thirDept.id || this.subDept.id || this.dept.id || 0
        const res = await this.$axios1.post("/doctor/list", this.param)
        this.doctorList = res.data;
      },
      line_All(e) {
        this.isConsult = true
        e.target.classList.add('line-active');
        e.target.parentNode.children[1].classList.remove('line-active');
        e.target.parentNode.children[2].classList.remove('line-active');
        this.oldDoctorList = [];
        this.getDoctor();
        this.doctorState = this.$t("staff.consultState");
        this.$refs.consult.classList.remove('department-active');
        this.$refs.consult_icon.classList.remove('van-icon-play-active');
      },
      on_line(e) {
        this.doctorState = this.$t("staff.online")
        this.$refs.consult.classList.remove('department-active');
        this.$refs.consult_icon.classList.remove('van-icon-play-active');
        this.isConsult = true
        e.target.classList.add('line-active');
        e.target.parentNode.children[0].classList.remove('line-active');
        e.target.parentNode.children[2].classList.remove('line-active');
        if (this.oldDoctorList.length === 0) {
          this.oldDoctorList = this.doctorList;
        } else if (this.oldDoctorList.length > 0) {
          const newDoctorList = [];
          this.oldDoctorList.forEach((item) => {
            if (item.userIsConsultable) {
              newDoctorList.push(item);
            }
          });
          this.doctorList = newDoctorList;
          return;
        }
        const newDoctorList = [];
        this.doctorList.forEach((item) => {
          if (item.userIsConsultable) {
            newDoctorList.push(item);
          }
        });
        this.doctorList = newDoctorList;
      },
      off_line(e) {
        this.doctorState = this.$t("staff.offline")
        this.$refs.consult.classList.remove('department-active');
        this.$refs.consult_icon.classList.remove('van-icon-play-active');
        this.isConsult = true
        e.target.classList.add('line-active');
        e.target.parentNode.children[1].classList.remove('line-active');
        e.target.parentNode.children[0].classList.remove('line-active');
        if (this.oldDoctorList.length === 0) {
          this.oldDoctorList = this.doctorList;
        } else if (this.oldDoctorList.length > 0) {
          const newDoctorList = [];
          this.oldDoctorList.forEach((item) => {
            if (!item.userIsConsultable) {
              newDoctorList.push(item);
            }
          });
          this.doctorList = newDoctorList;
          return;
        }
        const newDoctorList = [];
        this.doctorList.forEach((item) => {
          if (!item.userIsConsultable) {
            newDoctorList.push(item);
          }
        });
        this.doctorList = newDoctorList;
      },
      async showList2(item) {
        if (this.dept.id === item.id) {
          return
        }
        this.dept = item
        this.subDept = {subDepts:[]}
        this.thirDept = {}
        if (item.subDepts.length === 0) {
          this.isDepartment = true
          this.$refs.department_icon.classList.remove('van-icon-play-active');
          this.$refs.department.classList.remove('department-active');
          this.getDoctor()
        }
      },
      async showList3(item) {
        if (this.subDept.id === item.id) {
          return
        }
        this.subDept = item
        this.thirDept = {}
        if (item.subDepts.length === 0) {
          this.isDepartment = true
          this.$refs.department_icon.classList.remove('van-icon-play-active');
          this.$refs.department.classList.remove('department-active');
          this.getDoctor()
        }
      },
      async showList4(item) {
        this.thirDept = item
        this.isDepartment = true
        this.$refs.department_icon.classList.remove('van-icon-play-active');
        this.$refs.department.classList.remove('department-active');
        this.getDoctor()
      },
      handleSearch() {
        this.getDoctor()
      }
    }
  };
</script>

<style scoped lang="less">
  .app-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .search {
      display: flex;
      flex-direction: row;
      margin: 0.5rem 0.75rem 0.5rem 0.75rem;
      .van-search {
        background: #F5F6FA;
        color: #999999;
        height: 1.75rem;
        border-radius: 0.85rem;
        width: 80%;
      }
      .search-btn {
        width: 20%;
        line-height: 1.75rem;
        text-align: center;
        font-size: 0.75rem;
        color: #00a0e9;
      }
    }
    .doctorList {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .dropdown-menu {
        position: relative;
        width: 100%;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 2rem;
        box-sizing: border-box;
        padding: 0 0.75rem;
        background-color: #fff;
        line-height: 2rem;
        font-size: 0.7rem;
        color: #838385;
        text-align: center;
        border: 0.05rem solid #f5f6fa;
        .department {
          display: flex;
          justify-content: center;
          width: 50%;
        }
        .department-active {
          font-weight: 500;
          color: #333333;
        }
        .department-list-box {
          position: absolute;
          top: 2.4rem;
          left: 0;
          right: 0;
          height: 25rem;
          z-index: 100;
          .list-cont {
            display: flex;
            flex-direction: row;
            background-color: #F5F6FA;
            z-index: 100;
            .department-list {
              max-height: 8rem;
              overflow: hidden;
              overflow-y: auto;
              background-color: #fff;
              z-index: 9;
              width: 40%;
              .bgc_active {
                background: #f5f6fa !important;
              }
              .bgc2_active {
                background-color: #eceef3 !important;
              }
              .color_active {
                color: #49a7ff !important;
              }
              .list1 {
                height: 1.6rem;
                line-height: 1.6rem;
                text-align: left;
                overflow: hidden;
                background-color: #fff;
                .text1 {
                  font-size: 0.56rem;
                  font-weight: 400;
                  color: #838385;
                  margin-left: 0.52rem;
                }
                .img1 {
                  width: 0.64rem;
                  height: 0.64rem;
                  margin-left: 0.64rem;
                  margin-top: -0.1rem;
                }
              }
              .list1-sel {
                background-color: #F5F6FA;
                .text1 {
                  color: #49A7FF;
                }
              }
            }
            .list2_box {
              background-color: #f5f6fa;
              max-height: 8rem;
              overflow: hidden;
              overflow-y: auto;
              width: 30%;
              .list2 {
                height: 1.6rem;
                .img2 {
                  width: 0.64rem;
                  height: 0.64rem;
                  margin-left: 0.4rem;
                  margin-top: 0.52rem;
                }
                font-size: 0.56rem;
                font-weight: 400;
                color: #838385;
                z-index: 6;
              }
              .list2-sel {
                color: #49A7FF;
              }
            }
            .list3_box {
              background-color: #eceef3;
              overflow: hidden;
              max-height: 8rem;
              overflow-y: auto;
              z-index: 9;
              width: 30%;
              .list3 {
                height: 1.6rem;
                .img3 {
                  width: 0.64rem;
                  height: 0.64rem;
                  margin-left: 0.4rem;
                  margin-top: 0.52rem;
                  margin-right: 0.1rem;
                }
                font-size: 0.56rem;
                font-weight: 400;
                color: #838385;
              }
              .list3-sel {
                color: #49A7FF;
              }
            }
          }
        }
        .department-list-box-mask {
          position: absolute;
          top: 2.5rem;
          left: 0;
          right: 0;
          height: 25rem;
          content: "";
          display: block;
          background: #000000;
          opacity: 0.3;
          bottom: 0;
        }
        .consult-list {
          position: absolute;
          top: -.08rem;
          left: 0;
          right: 0;
          background-color: #fff;
          z-index: 9;
          text-align: center;
          font-size: 0.56rem;
          font-weight: 400;
          color: #838385;
          li {
            width: 100%;
            height: 2rem;
            line-height: 2rem;
            border-bottom: 0.05rem solid #F5F6FA;
          }
          .line-active{
            color: #49a7ff !important;
          }
        }
        .van-icon-play {
            font-size: 0.6rem;
            top: -0.05rem;
            transform: rotate(90deg);
            color: #b9b9b9;
        }
        .van-icon-play-active {
          transform: rotate(-90deg);
          color: #3387fd;
        }
        .consult {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }

      .van-dropdown-menu__bar {
        box-shadow: none;
      }
      .van-cell__left-icon {
        margin-right: 0.52rem;
      }
      .van-cell {
        font-size: 0.56rem;
        font-weight: 400;
        color: #838385;
      }

      .list-content {
        flex-grow: 1;
        overflow: scroll;
        height: 2.5rem;
        .doctor-content {
          width: 100%;
          display: flex;
          margin: 0.8rem 0;
          .head-portrait {
            width: 1.8rem;
            height: 1.8rem;
            margin-left: 0.64rem;
            border: .05rem solid #3588fd;
            border-radius: 50%;
            vertical-align:middle;
            display: block;
            background: #3588fd;
          }
          .content {
            margin-left: 0.4rem;
            margin-right: 0.64rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .content-title {
              height: 1rem;
              line-height: 1rem;
              display: flex;
              h2 {
                margin: 0;
                padding: 0;
                font-size: 0.64rem;
                font-weight: 500;
                color: #333333;
              }
              span {
                margin-left: 0.48rem;
                font-size: 0.52rem;
                font-weight: 400;
                color: #333333;
              }
              img {
                width: 0.64rem;
                height: 0.52rem;
                margin-left: 0.28rem;
                margin-top: 0.2rem;
              }
            }
            .content-text {
              height: 0.8rem;
              line-height: 0.8rem;
              font-size: 0.52rem;
              font-weight: 400;
              color: #838385;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
